import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { DPUDataService } from '@fieldos/data-services';
import { DPU, DPUCreateModel } from '@fieldos/models';
import { ToastStore } from '@fieldos/store/toast.store';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  removeEntity,
  setAllEntities,
  setEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { AuthStore } from '.';

interface DPUStoreState {
  loading: boolean;
}

export const DPUStore = signalStore(
  { providedIn: 'root' },
  withState<DPUStoreState>({
    loading: false,
  }),
  withEntities<DPU>(),
  withMethods(
    (store, service = inject(DPUDataService), toast = inject(ToastStore)) => ({
      async fetchAll() {
        patchState(store, { loading: true });
        try {
          const dpus = await service.fetchAll();
          patchState(store, setAllEntities(dpus.reverse()));
        } catch (err) {
          console.error('Error fetching work order dpus', err);
        } finally {
          patchState(store, { loading: false });
        }
      },
      async deleteDPU(dpu: DPU): Promise<void> {
        try {
          await service.delete(dpu.id);
          patchState(store, removeEntity(dpu.id));

          toast.showSuccessToast('work_orders.dpus.delete_success');
        } catch (err) {
          toast.showErrorToast('work_orders.dpus.delete_error');
          console.error('Error deleting dpu', err);
        }
      },
      async createDPUs(dpus: DPUCreateModel[]): Promise<number[]> {
        try {
          const result = await service.createMany(dpus);

          toast.showSuccessToast('work_orders.dpus.create_success');
          this.fetchAll();

          return result.map((e) => e.id);
        } catch (err) {
          toast.showErrorToast('work_orders.dpus.create_error');
          console.error('Error creating dpus', err);

          return [];
        }
      },
      async updateDPU(dpu: DPU): Promise<void> {
        try {
          await service.update(dpu);

          patchState(store, setEntity(dpu));

          toast.showSuccessToast('work_orders.dpus.update_success');
        } catch (err) {
          toast.showErrorToast('work_orders.dpus.update_error');
          console.error('Error updating dpu', err);
        }
      },
      async updateWorkOrderDPUs(
        workOrderId: number,
        dpus: number[]
      ): Promise<boolean> {
        try {
          await service.updateWorkOrderDPUs(workOrderId, dpus);

          toast.showSuccessToast('work_orders.dpus.update_success');
          return true;
        } catch (err) {
          if (err instanceof HttpErrorResponse && err.status === 400) {
            toast.showErrorToast(err.message);
          } else {
            toast.showErrorToast('work_orders.dpus.update_error');
            console.error('Error updating work order dpus', err);
          }

          return false;
        }
      },
    })
  ),
  withHooks({
    onInit(store, authStore = inject(AuthStore)) {
      toObservable(authStore.isAuthenticated)
        .pipe(
          distinctUntilChanged(),
          filter((isAuthenticated) => isAuthenticated === true),
          tap(() => store.fetchAll()),
          takeUntilDestroyed()
        )
        .subscribe();
    },
  })
);
